import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import kebabCase from "lodash.kebabcase"

import * as styles from "../../styles/styles.module.css"
import BaseWidget from "./basewidget"

const TermWidget = () => {
  const data = useStaticQuery(graphql`
    query termWidgetQuery {
      termsGroup: allPost(limit: 1000) {
        group(field: { terms: SELECT }) {
          label: fieldValue
        }
      }
    }
  `)

  const terms = data.termsGroup.group

  return (
    <BaseWidget title="タグ">
      <div className={styles.flexRowLeft}>
        {terms.map(term => {
          return (
            <Link
              to={`/t/${kebabCase(term.label)}`}
              key={term.label}
              className={styles.marginHItem}
            >
              {term.label}
            </Link>
          )
        })}
      </div>
    </BaseWidget>
  )
}

export default TermWidget
