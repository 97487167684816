import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import * as styles from "../../styles/styles.module.css"
import BaseWidget from "./basewidget"

const YearWidget = () => {
  const data = useStaticQuery(graphql`
    query yearWidgetQuery {
      yearsGroup: allPost(limit: 1000) {
        group(field: { publishedYear: SELECT }) {
          rawYear: fieldValue
          totalCount
        }
      }
    }
  `)

  // 年の降順に並べる
  const years = data.yearsGroup.group
    .map(({ rawYear, totalCount }) => ({
      year: parseInt(rawYear, 10),
      totalCount,
    }))
    .sort(sorter)

  return (
    <BaseWidget title="年別">
      <div className={styles.flexRowLeft}>
        {years.map(({ year, totalCount }) => {
          return (
            <Link to={`/${year}`} key={year} className={styles.marginHItem}>
              {`${year} (${totalCount})`}
            </Link>
          )
        })}
      </div>
    </BaseWidget>
  )
}

const sorter = (a, b) => {
  if (a.year < b.year) {
    return 1
  }
  if (a.year > b.year) {
    return -1
  }

  return 0
}

export default YearWidget
