import * as React from "react"
import { useCallback, useState, useEffect } from "react"

import { MODES, useColorMode } from "../hooks/useColorMode"
import { Sun, Moon } from "./icons"

type Props = {
  className: string
}

const ColorModeSwitch: React.FC<Props> = ({ className }) => {
  const [mounted, setMounted] = useState(false)
  const [colorMode, switchColorMode] = useColorMode()

  const onClick = useCallback(
    event => {
      switchColorMode()
    },
    [switchColorMode],
  )

  useEffect(() => {
    setMounted(true)
  }, [setMounted])

  if (!mounted) {
    return <span className={className}></span>
  }

  return (
    <button
      className={className}
      onClick={onClick}
      aria-label="カラーモードを切り替え"
    >
      {colorMode === MODES.Light ? <Sun /> : <Moon />}
    </button>
  )
}

export default ColorModeSwitch
