import React from "react"

import * as styles from "../../styles/styles.module.css"

const BaseWidget = ({ title, children, hideTitle }) => {
  const className = hideTitle ? styles.widgetTitleHidden : styles.widgetTitle
  return (
    <div className={styles.widget}>
      <h2 className={className}>{title}</h2>
      {children}
    </div>
  )
}

export default BaseWidget
