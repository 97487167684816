/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import * as React from "react"

// 使用するフォント
import "@fontsource/montserrat/700.css"
import "@fontsource/fira-code/700.css"
import "@fontsource/fira-code/400.css"

// Prism テーマ
import "./src/styles/prismjs/prism-okaidia.css"

import "./src/styles/global.css"

import Layout from "./src/components/layout"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
