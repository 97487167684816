import * as React from "react"
import { Link } from "gatsby"

import * as styles from "../styles/styles.module.css"

type Props = {
  siteTitle: string
}

const Header = ({ siteTitle = "" }: Props): JSX.Element => (
  <header
    style={{
      marginBottom: `3rem`,
    }}
  >
    <div
      className={styles.flexRowCenter}
      style={{
        margin: `0 auto`,
        padding: `4rem 1.0875rem`,
      }}
    >
      <h1 className={styles.siteTitle}>
        <Link to="/" className={styles.siteTitleLink}>
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

export default Header
