/**
 * カラーモード（ダークモード）スイッチ用のフック
 */
import { useState, useCallback } from "react"

export const MODES = {
  Light: `light`,
  Dark: `dark`,
}
const ALL_MODES = Object.values(MODES)

const getInitialTheme = _ => {
  if (typeof window !== `undefined`) {
    let theme = window?.localStorage?.theme
    if (theme) {
      if (ALL_MODES.includes(theme)) {
        return theme
      }
    }

    if (window?.matchMedia(`(prefers-color-scheme: dark)`).matches) {
      return MODES.Dark
    }
  }

  return MODES.Light
}

export const useColorMode = () => {
  const [colorMode, setColorModeReal] = useState(getInitialTheme)

  const switchColorMode = useCallback(() => {
    const newColorMode = colorMode === MODES.Dark ? MODES.Light : MODES.Dark

    // See: https://tailwindcss.com/docs/dark-mode#supporting-system-preference-and-manual-selection
    const classList = document.documentElement.classList
    if (newColorMode === MODES.Dark) {
      classList.add(MODES.Dark)
    } else {
      classList.remove(MODES.Dark)
    }

    localStorage.theme = newColorMode
    setColorModeReal(newColorMode)
  }, [colorMode])

  return [colorMode, switchColorMode]
}
